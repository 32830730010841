<template>
  <div class="project-floor-plan-allocate-agents">
    <div class="container fluid">
      <h3 class="m-2">Allocate Agents</h3>

      <!-- Project Info -->
      <project-detail-header :project="project"> </project-detail-header>

      <!-- Allocated agents -->
      <div class="card p-2 mt-2">
        <h4 class="p-2 mb-4">Allocated</h4>

        <filter-panel
          v-model="isFilterActive"
          bordered
          class="filter my-4"
          @clear="clearFilter"
          @filter="filterData"
        >
          <div class="row p-2">
            <fd-input
              v-model="filter['agent:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Agent Name"
              name="agentName"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="filter['agent:agencyBranch:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Branch"
              name="branchName"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="filter['agent:email']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="agentEmail"
              type="email"
            >
            </fd-input>
            <fd-input
              v-model="filter['agent:contact']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Contact No."
              name="agentContact"
              type="text"
            >
            </fd-input>
          </div>
        </filter-panel>

        <div class="d-flex justify-content-end mb-2">
          <dropdown-button
            v-if="showActionButton"
            buttonClass="bordered main mr-1"
          >
            <template #buttonContent>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <div class="card text-left">
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="setAgentsPrivate"
              >
                <i class="fas fa-comment-slash mr-1"></i>
                <b>Set private</b>
                <p class="fg-grey-600">
                  Set these agents to be excluded from the chat rotation.
                </p>
              </fd-list-item>
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="setAgentsPublic"
              >
                <i class="fas fa-comment mr-1"></i>
                <b>Set public</b>
                <p class="fg-grey-600">
                  Set these agents to be available in the chat rotation.
                </p>
              </fd-list-item>
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="removeAgentsAllocation"
              >
                <i class="fas fa-user-minus mr-1"></i>
                <b>Remove</b>
                <p class="fg-grey-600">
                  Remove allocated agents from the list.
                </p>
              </fd-list-item>
            </div>
          </dropdown-button>
          <fd-button class="main" @click="toNewAllocationPage">
            <i class="fas fa-plus mr-1"></i> New Allocation
          </fd-button>
        </div>

        <vue-good-table
          ref="allocatedAgentsTable"
          mode="remote"
          @on-selected-rows-change="onSelectAgents"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="allocatedAgentsColumn"
          :rows="allocatedAgentsData"
          :totalRows="allocatedAgentsPagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :isLoading="isAgentsLoading"
          :sort-options="{
            enabled: false
          }"
          :select-options="{ enabled: true, disableSelectInfo: true }"
        >
          <!-- Loading -->
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <!-- Empty State -->
          <div slot="emptystate">
            <no-data message="There are no agents allocated.">
              <fd-button
                class="main mt-2"
                :compact="false"
                @click="toNewAllocationPage"
              >
                Allocate Agents
              </fd-button>
            </no-data>
          </div>
          <!-- Table actions -->
          <div slot="table-actions" class="px-2 py-1">
            <fd-button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </fd-button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'allocationType'">
              <chip
                :class="[
                  {
                    'bg-main': props.row.canChat,
                    'bg-accent2': !props.row.canChat
                  },
                  'fg-white'
                ]"
                >{{ props.row.canChat ? "Public" : "Private" }}</chip
              >
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectAgentAllocation as allocationAPI } from "@/api";
import { ProjectAgentAllocationModel } from "@/models";
import { toTitleCase } from "@/utils/string";
import allocateAgentsMixin from "@/mixins/projectAllocateAgents";

import ProjectAPI from "@/modules/Project/api/project";
const projectAPI = new ProjectAPI("agency");

export default {
  components: {
    ProjectDetailHeader: () =>
      import("@/modules/Project/components/ProjectDetail/Header"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [vueGoodTable, allocateAgentsMixin],
  props: {},
  data: function () {
    return {
      isProjectLoading: true,
      isAgentsLoading: false,

      isfloorPlanShown: false,
      project: {},

      allocatedAgentsColumn: [
        {
          label: "Agent",
          field: "agent.name"
        },
        {
          label: "Type",
          field: "allocationType"
        },
        {
          label: "Branch",
          field: "agent.agencyBranch.name"
        },
        {
          label: "Email",
          field: "agent.user.email"
        },
        {
          label: "Contact No.",
          field: (row) => this.$getUserPhoneNumber(row.agent.user)
        }
      ],
      allocatedAgentsPagination: {
        total: 1
      },
      tableParams: {
        page: 1,
        perPage: 30
      },
      allocatedAgentsData: [],

      isFilterActive: false,
      filter: {
        "agent:name[partial]": "",
        "agent:email": "",
        "agent:contact": "",
        "agent:agencyBranch:name[partial]": ""
      }
    };
  },
  computed: {
    showActionButton: {
      cache: false,
      get() {
        return this.$refs.allocatedAgentsTable?.selectedRows?.length > 0;
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getProject();
      this.getAllocatedAgents();
    },

    toNewAllocationPage() {
      this.$router.push({
        name: "ProjectAllocateAgentsAdd",
        params: {
          id: this.$route.params.id
        }
      });
    },

    // ============================= Table Related =============================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllocatedAgents();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllocatedAgents();
    },

    onSelectAgents(params) {
      let selectedAgents = params.selectedRows;
      this.$refs.allocatedAgentsTable.selectedRows;
    },
    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    filterData() {
      this.tableParams.page = 1;
      this.getAllocatedAgents();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllocatedAgents();
    },
    // ============================= API Functions =============================
    async getProject() {
      try {
        this.isProjectLoading = true;

        let data = await projectAPI.getProject(this.$route.params.id);
        this.project = data;
        this.isProjectLoading = false;
      } catch (error) {
        this.isProjectLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async getAllocatedAgents() {
      try {
        this.isAgentsLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await allocationAPI.getAllocatedAgents(
          this.$route.params.id,
          {
            queries: filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );
        this.allocatedAgentsData = this._.cloneDeep(data.data);
        this.allocatedAgentsPagination = this._.cloneDeep(data.meta.pagination);

        this.isAgentsLoading = false;
      } catch (error) {
        this.isAgentsLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get allocated agents. Please try again later."
        });
      }
    },
    async removeAgentsAllocation() {
      let numberOfAgentsToRemove = this.$refs.allocatedAgentsTable?.selectedRows
        ?.length;
      let confirm = await this.$confirm({
        title: "Remove Allocated Agent",
        message: `Are you sure u want to remove the ${numberOfAgentsToRemove} selected agents from allocated list?`,
        type: "alert",
        confirmText: "Remove"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          let agentsToBeRemoved = this.$refs.allocatedAgentsTable.selectedRows.map(
            (row) => ({
              id: row.agent.id,
              name: row.agent.name
            })
          );

          let res = await allocationAPI.removeAgents(this.$route.params.id, {
            agents: agentsToBeRemoved
          });
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Allocated agents are removed successfully."
          });

          this.handlePartialFailedOp(res, "removed"); // mixin

          this.getAllocatedAgents();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to remove allocated agents. Please try again later."
          });
        }
      }
    },

    getUpdateAgentsPayload(isPublic) {
      return this.$refs.allocatedAgentsTable.selectedRows.map((row) => {
        row.canChat = isPublic;
        return row;
      });
    },
    getConfirmModalContext(type) {
      let numberOfAgentsToUpdate = this.$refs.allocatedAgentsTable?.selectedRows
        ?.length;

      return {
        title: `Update Agents to ${toTitleCase(type)}`,
        message: `Are you sure u want to update the ${numberOfAgentsToUpdate} selected agents from allocated list to ${type}?`,
        confirmText: `Set ${toTitleCase(type)}`
      };
    },

    async setAgentsPrivate() {
      let confirm = await this.$confirm(this.getConfirmModalContext("private"));

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          let payload = this.getUpdateAgentsPayload(false);

          let res = await allocationAPI.updateAgents(
            this.$route.params.id,
            ProjectAgentAllocationModel.putPayload(payload)
          );
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Successfully updated",
            text: "Agents have been changed to private type."
          });

          this.handlePartialFailedOp(res, "set to private"); // mixin

          this.getAllocatedAgents();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to execute the operation. Please try again later."
          });
        }
      }
    },
    async setAgentsPublic() {
      let confirm = await this.$confirm(this.getConfirmModalContext("public"));

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          let payload = this.getUpdateAgentsPayload(true);

          let res = await allocationAPI.updateAgents(
            this.$route.params.id,
            ProjectAgentAllocationModel.putPayload(payload)
          );
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Successfully updated",
            text: "Agents have been changed to public type."
          });

          this.handlePartialFailedOp(res, "set to public"); // mixin

          this.getAllocatedAgents();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to set agents to public. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
